/* eslint-disable @next/next/no-img-element */
import Image from "next/legacy/image"
import Link from 'next/link';

const UNIQUE_SELLING_POINT_LIST = Object.freeze([
  {
    title: 'Made in Australia',
    description: 'Our frames are manufactured in Sydney, by us.',
    icon: <Image className="mt-[8px]" width={96} height={96} decoding="async" alt="Australia Map Icon" src="/images/homepage/icons/icon_australiamap@2x.png" />,
    path: '/about-us/our-story '
  },
  {
    title: 'Sustainable',
    description:
      'Made from recycled and recyclable materials that don’t cost the earth.',
    icon: <Image width={96} height={96} decoding="async" alt="Sustainable Icon" src="/images/homepage/icons/icon_sustainable@2x.png" />,
    path: '/about-us/our-story'
  },
  {
    title: '10 Year Frame Warranty',
    description:
      'Built to last. No questions asked - you break them, we’ll replace them',
      icon: <Image width={96} height={96} decoding="async" alt="10 Year Warranty Icon" src="/images/homepage/icons/icon_tenyearframewarranty@2x.png" />,
    path: '/our-glasses/warranty'
  },
  {
    title: 'Mix & Match',
    description: 'Customise your frames with modular parts',
    icon: <Image width={96} height={96} decoding="async" alt="Mix & Match Icon" src="/images/homepage/icons/icon_mixmatch@2x.png" />,
    path: '/our-glasses/our-frame-system'
  },
  {
    title: 'Easy Returns',
    description:
      'Your money back if you change your mind in the first 30 days, no questions asked.',
    icon: <Image className="ml-[7px] mt-[4px]" width={96} height={96} decoding="async" alt="Easy Returns Icon" src="/images/homepage/icons/icon_easyreturns@2x.png" />,
    path: '/our-glasses/warranty'
  },
  {
    title: 'Affordable',
    description:
      'Made for everyone, and every day - without the ridiculous price tag.',
    icon: <Image className="mt-[7px]" width={96} height={96} decoding="async" alt="Affordable Icon" src="/images/homepage/icons/icon_affordable@2x.png" />,
    path: '/our-glasses/our-prices'
  }
]);

const HomepageUniqueSellingPoints = () => (
  <section className="bg-white py-[40px] px-4 sm:py-[88px]">
    <div className="mx-auto w-full max-w-[1224px]">
      <h1 className="mb-[30px] text-center text-[24px] font-semibold text-brand-black100 md:text-[36px]">
        Your Dresden Glasses
      </h1>
      <div
        className={`grid grid-cols-2 gap-[30px] md:grid-cols-3 md:gap-[40px] lg:grid-cols-6 lg:gap-[14px]`}>
        {UNIQUE_SELLING_POINT_LIST.map((uniqueSellingPoint) => {
          return (
            (<Link
              key={uniqueSellingPoint.title}
              prefetch={false}
              href={uniqueSellingPoint.path}
              className="flex flex-col items-center rounded-[8px] py-[20px] px-[14px] text-center transition-shadow duration-700 hover:shadow-card">

              <div className="mb-[16px] flex h-[96px] w-[96px] flex-shrink-0 items-center justify-center rounded-full bg-brand-grey250">
                {uniqueSellingPoint.icon}
              </div>
              <div className="flex flex-col items-center">
                <div className="flex-shrink-0 text-[14px] font-medium">{uniqueSellingPoint.title}</div>
                <div className="mt-[8px] hidden text-[13px] leading-[14px] text-brand-grey300 md:block">
                {uniqueSellingPoint.description}
                </div>
              </div>

            </Link>)
          );
        })}
      </div>
    </div>
  </section>
);

export default HomepageUniqueSellingPoints;
